/**
 * TODO: Convert this to scss, and convert all css files to scss. 
 With regards to themes.scss, use as needed.
 */


/* You can add global styles to this file, and also import other style files */

@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "intl-tel-input/build/css/intlTelInput.css";
@import "~leaflet/dist/leaflet.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(/assets/fonts/MaterialIcons-Regular.ttf);
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

h1 {
  color: #369;
  font-size: 250%;
}

h2,
h3 {
  color: #444;
  font-weight: lighter;
}

body {
  --background-color: #f4f6f9;
  /* margin: 2em; */
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

body,
input[text],
button {
  color: #888;
  font-family: Metropolis;
}

/* everywhere else */

* {
  font-family: Metropolis;
}

*:lang(he):not(.material-icons){
  font-family: OpenSans;
}


.mat-pseudo-checkbox-checked {
  background: #3f8de4;
}

.pairing-dialog .mat-dialog-container {
  padding: 0;
  border-radius: 4px;
}
.warning-dialog .mat-dialog-container {
  padding: 0;
  border-radius: 4px;
}

.success-msg {
  background-color: #94cd4f !important;
  padding: 22px !important;
}
.success-msg.mat-snack-bar-container{
  max-width: 36vw;
}

.success-msg.error {
  background-color: #e3194a !important;
}

.success-msg.warning {
  background-color: #eaa343 !important;
}

.success-msg.warning.more-info .mat-simple-snack-bar-content{
  display: inline-block;
  white-space: pre-wrap;
  line-height: 22px;
}

.success-msg .mat-simple-snackbar-action {
  color: white;
  margin-left: 50px;
}
.success-msg.warning.more-info .mat-simple-snackbar-action {
  color: white;
  margin-left: 40px;
}

.success-msg .mat-simple-snackbar {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: white;
}

.iti--separate-dial-code .iti__selected-flag {
  background: transparent;
}

.tooltip-container{
  position: absolute;
  width: 200px;
  height: 70px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  padding: 16px;
  z-index: 10;
  color: #000000;
  line-height: 14px;
  text-align: center;
  pointer-events: none;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.tooltip-container::after{
  content: ''; 
  position: absolute;
  left: -30px;
  border: 15px solid transparent; 
  border-right: 20px solid #FFFFFF; 
}

.tooltip-container.left::after{
  left: 98%;
  border: 15px solid transparent; 
  border-left: 20px solid #FFFFFF; 
}

.tooltip-container h6 {
  color: #ff0000;
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 7px;
}

.iti__country {
  direction: ltr;
}

[dir="rtl"] .owl-dt-timer-divider {
  right: -0.3em;
  left: initial;
}

[dir="rtl"] .owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-to {
  border-top-left-radius: 999px;
  border-bottom-left-radius: 999px;
  border-top-right-radius: initial;
  border-bottom-right-radius: initial;
}

[dir="rtl"] .owl-dt-calendar-table .owl-dt-calendar-cell-in-range.owl-dt-calendar-cell-range-from {
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
  border-top-right-radius: 999px;
  border-bottom-right-radius: 999px;
}

.mat-tooltip.tooltip {
  font-size: 14px;
  white-space: pre-line;
}

.mat-tooltip.tooltip-w-450 {
  max-width: 450px;
}

.mat-select-value {
  vertical-align: middle;
}

.mat-select > div {
  height: 100%;
}

.mat-select > span {
  font-weight: 500;
}

.mat-tab-body {
  animation: fade-out 0.25s;
  opacity: 0.5;
}
.mat-tab-body.mat-tab-body-active {
  animation: fade-in 0.25s;
  opacity: 1;
}

@keyframes fade-in {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
