@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
$mat-walabot: (
  50: #e8f1fc,
  100: #c5ddf7,
  200: #9fc6f2,
  300: #79afec,
  400: #5c9ee8,
  500: #3f8de4,
  600: #3985e1,
  700: #317add,
  800: #2970d9,
  900: #1b5dd1,
  A100: #ffffff,
  A200: #d3e1ff,
  A400: #a0bfff,
  A700: #86aeff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$walabot-app-primary: mat.define-palette($mat-walabot, 500);
$walabot-app-accent: mat.define-palette($mat-walabot, 500, A100, A400);

// The warn palette is optional (defaults to red).
$walabot-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$walabot-app-theme: mat.define-light-theme(
  $walabot-app-primary,
  $walabot-app-accent,
  $walabot-app-warn
);

@font-face {
  font-family: Metropolis;
  src: local(Metropolis-Regular),
    url(/assets/fonts/Metropolis-Regular.woff) format("woff");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Metropolis;
  src: local(Metropolis-Medium),
    url(/assets/fonts/Metropolis-Medium.woff) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Metropolis;
  src: local(Metropolis-SemiBold),
    url(/assets/fonts/Metropolis-SemiBold.woff) format("woff");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: Metropolis;
  src: local(Metropolis-Light),
    url(/assets/fonts/Metropolis-Light.woff) format("woff");
  font-style: normal;
  font-weight: lighter;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Regular),
    url(/assets/fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Medium),
    url(/assets/fonts/OpenSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-SemiBold),
    url(/assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: OpenSans;
  src: local(OpenSans-Light),
    url(/assets/fonts/OpenSans-Light.ttf) format("truetype");
  font-style: normal;
  font-weight: lighter;
  font-stretch: normal;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($walabot-app-theme);

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: mat.get-color-from-palette($walabot-app-primary);
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: mat.get-color-from-palette($walabot-app-accent);
}
